<template>
  <div>
    <div class="mainBox" v-for="(item,index) in pric" :key="index">
      <!-- 左 -->
      <div class="leftBox" >
        <div class="text">{{item.name}}</div>
      </div>
      <!-- 右 -->
      <div class="middle" >
        <div class="content" v-for="(v,i) in item.son" :key="i">
          <div class="sco">
            <div class="circular" :class="index==0&&i==0?'distinction':''"></div>
            <div class="line"></div>
          </div>
          <!-- 圆点 -->

          <!-- 竖线 -->

          <!-- 信息内容 -->
          <div class="information">

            <p class="head">处理信息:</p>
            <p class="contentTexte">{{v.info}}</p>
            <el-image :preview-src-list="[vv]" v-for="vv in v.picture" :key="vv" style="
              width: 100px;
              height: 100px;
              margin-top: 10px;
              margin-right: 10px;
            " :src="vv" />
            <p class="time">{{v.create_time}}</p>

            <!-- 底部间隔线 -->
            <div class="bottomInterval"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  props: {
    pric: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.mainBox {
  // height: 1000px;
  display: flex;
  .leftBox,
  .middle,
  .rightBox {
    // height: 200px;
    // padding-top: 10px;
    .text {
      color: #06b7ae;
      margin-left: 40%;
      text-align: end;
    }
  }
  .leftBox {
    width: 20%;
    font-size: 18px;
    .text {
      margin-left: 30%;
    }
    // border: 1px solid #000;
  }
  .middle {
    flex: 1;
    width: 5%;
    // border: 1px solid #000;
    .content {
      margin-left: 20px;
      display: flex;
    }
    .sco {
      width: 18px;
      margin-right: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .line {
        height: 100%;
        background-color: #d4d6d9;
        width: 2px;
        margin-left: 8px;
        margin-top: 2px;
        margin-bottom: 2px;
      }
    }
    .distinction {
      background: #06b7ae !important;
    }
    .circular {
      width: 18px;
      height: 23px;
      background: #d4d6d9;
      border-radius: 50%;
    }

    p {
      padding: 0;
      margin: 0;
      color: rgb(130, 131, 134);
      font-size: 14px;
      // line-height: 0;
    }
    .information {
      // margin-top: -20px;
      margin-left: 20px;
      padding-bottom: 10px;
      // margin-bottom: 5px;
      border-bottom: 2px dashed rgb(244, 244, 244);
      line-height: 20px;
      .contentTexte {
        margin-bottom: 10px;
      }
    }
    .bottomInterval {
      width: 100%;
      height: 20px;
    }
  }
}
</style>
