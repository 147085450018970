<template>
  <div class="upda">
    <el-upload
      ref="upload"
      :disabled="disabled"
      :action="Actions"
      list-type="picture-card"
      :data="uploadData"
      accept='.png , .jpg , .jpeg'
      :file-list="imglisted"
      :headers="token"
      :before-upload="beforeAvatarUpload"
      :on-preview="handlePreview"
      :on-progress="handleExceed"
      :auto-upload="true"
      :show-file-list="true"
      :on-remove="
        (response, file) => {
          handleRemove(response, file);
        }
      "
      :on-success="
        (response, file) => {
          onSuccess(response, file);
        }
      "
      :limit="imgnumber"
    >
      <i slot="default" class="el-icon-plus" />
    </el-upload>
    <el-dialog
      :visible.sync="dialogVisible"
      custom-class="el-dialog-s"
      append-to-body
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { wholeUrl } from "@/api/phpUrl"; //引入接口
import compressImage from "@/utils/compressImage.js";
export default {
  name: "Upimg",
  props: {
    // 最多上传照片数量
    imgnumber: {
      type: Number,
      default: null,
    },
    // id
    id: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    imglist: {
      type: Array,
      default: undefined,
    },
  },

  data() {
    return {
      Actions: wholeUrl + "/basis/upload",
      token: {},
      imglisted: [],
      // 图片放大显示
      dialogImageUrl: "",
      // 图片放大显示弹窗
      dialogVisible: false,
      lostImg: "", //本地图片地址
    };
  },
  created() {
    this.token = { TOKEN: getToken() };
    if (this.imglist) {
      this.imglisted = this.imglist;
    }
  },
  computed: {
    uploadData() {
      return {
        type: 3,
        file: this.dialogImageUrl,
      };
    },
  },
  methods: {
    async beforeAvatarUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      if (!suffix && !suffix2 && !suffix3) {
        this.$message.error("上传图片仅支持png、jpg、jpeg图片格式！");
        return false;
      }
      return suffix || suffix2 || suffix3;
    },
    handleExceed(file) {
      if (file.loaded > 5000000) {
        this.$message.error("上传图片最大不能超过5MB");
        this.$refs.upload.abort();
      }
    },
    // 图片放大显示
    handlePreview(file) {
      //console.log(file, "qwertyui111");
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      this.imglisted;
    },
    // 图片下载回显
    onSuccess(response, file) {
      var imgs = response.data.url.substring(response.data.url.lastIndexOf(".") + 1);
      
      this.lostImg = file.url.slice(5, file.url.length);
      //console.log(this.lostImg);
      //console.log(response);
      this.bd = response;
      this.imglisted.push({ url: response.data.full_url });
      let img = [];
      if (this.id) {
        img = [{ qualifyUrl: response.data.full_url, id: this.id }];
        this.$emit("upimg", img);
      } else {
        this.imglisted.forEach((item) => {
          img.push({ url: item.url });
        });
        this.$emit("upimg", img);
      }
      if(imgs!='jpg'&&imgs!='png'&&imgs!='jpeg'){
        this.handleRemove(response, file)
      }
    },
    // 删除图片
    handleRemove(response, file) {
      // console.log(response, file)
      this.imglisted.forEach((v, i) => {
        if (v.url == response.url) {
          this.$nextTick((_) => {
            this.imglisted.splice(i, 1);
          });
        }
        if (v.url === response.data.full_url) {
          this.$nextTick((_) => {
            this.imglisted.splice(i, 1);
          });
        }
      });
      let img = [];
      if (this.id) {
        img = [{ id: this.id, qualifyUrl: "" }];
        this.$emit("upimg", img);
      } else {
        this.imglisted.forEach((v) => {
          img.push({ url: v.url });
        });
        this.$emit("upimg", img);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
}
.el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
</style>
