<template>
  <!--  -->
  <div style="margin: 20px" class="main orderDetails aftersales">
    <!-- 状态条 -->
    <div class="statusBar" />
    <!-- 状态信息 -->
    <div class="statusInformation">
      <!-- 左 -->
      <div class="statusLeft">
        <div class="complete">
          <span v-if="state">平台介入</span>
          <span v-else>{{ detailedData.state }}</span>
          <i class="el-icon-refresh" style="cursor: pointer" @click="getOrderDetails" />
        </div>
        <div class="information">
          <p>
            售后单号：<span>{{ detailedData.id }}</span>
          </p>
          <p>
            售后单类型：<span>{{ detailedData.type }}</span>
          </p>
          <p>
            退款状态：<span>{{ detailedData.refund_status }}</span>
          </p>
          <p>
            介入进度：<span>{{ detailedData.status_ || "/" }}</span>
          </p>
          <p>
            申请时间：<span>{{ detailedData.create_time }}</span>
          </p>
        </div>
      </div>
      <!-- 中 -->
      <div class="statusMiddle">
        <p class="text">售后进度</p>
      </div>
      <!-- 右 -->
      <div class="statusRight">
        <div class="timeline">
          <Logistics :pric="this.logisticsIf"></Logistics>
        </div>
      </div>
    </div>

    <!-- 表格商品信息 -->
    <!-- <el-table
      :data="detailedData.goods"
      style="width: 100%; margin-top: 20px"
      :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
    >
      <el-table-column prop="date" label="商品信息" width="500">
        <template slot-scope="scope">
          <div class="shopMain">
            <div class="shop">
              <img
                :src="scope.row.picture_url"
                style="width: 100px; height: 100px"
                alt=""
              />
            </div>
            <div class="shopText">
              <h4>{{ scope.row.name }}</h4>
              <p>
                商品编码：<span>{{ scope.row.unique_id }}</span>
              </p>
              <p>
                生产厂家：<span>{{ scope.row.manufacturer }}</span>
              </p>
              <p>
                规格：<span>{{ scope.row.specification }}</span>
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="selling_price" label="购买单价" />
      <el-table-column prop="quantity" label="购买数量" />
      <el-table-column prop="deliver_goods_num" label="发货数量" />
      <el-table-column prop="after_sale_num" label="退货数量" />
      <el-table-column label="退款金额">
        <template slot-scope="scope">
          <span style="color: red">￥{{ scope.row.refundAmount }}</span>
        </template>
      </el-table-column>
    </el-table> -->

    <div class="tablebox">
      <div class="fx tabhe">
        <div style="flex: 30">商品信息</div>
        <div style="flex: 13">购买单价</div>
        <div style="flex: 13">购买数量</div>
        <div style="flex: 13">发货数量</div>
        <div style="flex: 13">退货数量</div>
        <div style="flex: 13">退款金额</div>
      </div>
      <div v-for="(v, i) in detailedData.goods" :key="i">
        <div class="fx tabhes">
          <div style="flex: 30; text-align: left">
            <div class="fx">
              <div>
                <img :src="v.picture_url" style="width: 100px; height: 100px" alt="" />
              </div>
              <div class="shopText">
                <h4>
                  <span v-if="v.tag == '换购'">换购商品</span>{{ v.name }}
                </h4>
                <p>
                  商品编码：<span>{{ v.unique_id }}</span>
                </p>
                <p>
                  生产厂家：<span>{{ v.manufacturer }}</span>
                </p>
                <p>
                  规格：<span>{{ v.specification }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="lsted">¥{{ v.selling_price }}</div>
          <div class="lsted">×{{ v.quantity }}</div>
          <div class="lsted">×{{ v.deliver_goods_num }}</div>
          <div class="lsted">×{{ v.after_sale_num }}</div>
          <div class="lsted">
            <span style="color: red">￥{{ v.refundAmount }}</span>
          </div>
        </div>
        <div v-if="v.son && v.son.length > 0" class="zengd">
          <div v-for="(item, index) in v.son" :key="index">
            【赠品】{{ item.gift_name }} ×{{ item.gift_quantity }}
          </div>
        </div>
      </div>
    </div>
    <!-- 退款合计金额 -->
    <div class="refund">
      <span class="refundText">退款合计金额</span>
      <span class="money">￥<span>{{ detailedData.refund_amount }}</span></span>
    </div>
    <!-- 售后单信息 -->
    <div class="aftersale">
      <h3 class="afterTitle">售后信息</h3>
      <div class="listData">
        <div class="listTitle borderTop">订单编号</div>
        <div class="listContent borderTop">{{ infoData.order_id }}</div>
      </div>
      <div class="listData">
        <div class="listTitle">支付方式</div>
        <div class="listContent">{{ infoData.pay_type_name }}</div>
      </div>
      <div class="listData">
        <div class="listTitle">预计退款至</div>
        <div class="listContent">{{ infoData.user_name }}</div>
      </div>
      <div class="listData">
        <div class="listTitle">货物状态</div>
        <div class="listContent">{{ infoData.cargo_status_name }}</div>
      </div>
      <div class="listData">
        <div class="listTitle">申请原因</div>
        <div class="listContent">{{ infoData.reason_msg }}</div>
      </div>
      <div class="listData">
        <div class="listTitle">问题描述</div>
        <div class="listContent">
          <p style="line-height: 25px">
            {{ infoData.buyer_initial_description }}
          </p>
          <el-image :preview-src-list="[item]" v-for="item in infoData.buyer_initial_description_pic" :key="item" style="
              width: 100px;
              height: 100px;
              margin-top: 10px;
              margin-right: 10px;
            " :src="item" />
        </div>
      </div>
      <div class="listData">
        <div class="listTitle">驳回原因</div>
        <div class="listContent">{{ infoData.rejected_reason }}</div>
      </div>
      <div class="listData">
        <div class="listTitle">客户举证</div>
        <div class="listContent">
          <p>{{ infoData.buyer_description }}</p>
          <el-image :preview-src-list="[item]" v-for="item in infoData.buyer_description_pic" :key="item" style="
              width: 100px;
              height: 100px;
              margin-top: 10px;
              margin-right: 10px;
            " :src="item" />
        </div>
      </div>
      <div class="listData">
        <div class="listTitle">供应商举证</div>
        <div class="listContent">
          <div>
            {{ infoData.business_description || "供应商暂未填写举证信息" }}
          </div>
          <el-image :preview-src-list="[item]" v-for="item in infoData.business_description_pic" :key="item" style="
              width: 100px;
              height: 100px;
              margin-top: 10px;
              margin-right: 10px;
            " :src="item" />
        </div>
      </div>
      <div class="listData">
        <div class="listTitle">处理意见</div>
        <div class="listContent">
          <span>{{ infoData.description || "平台尚未处理服务单" }}</span>
        </div>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div class="bomBtn" v-if="state === true ? true : false">
      <el-button @click="ofMethod()">返回</el-button>
      <el-button type="success" @click="emptyDate()">处理</el-button>
    </div>
    <div v-else></div>

    <!-- 审核通过提示框 -->
    <el-dialog title="审核通过提醒" :visible.sync="approved" width="30%">
      <span>
        确定通过当前售后订单？通过后您需要进行收货操作。
        请仔细核对售后商品信息、数量和退款金额。
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="approved = false">取 消</el-button>
        <el-button type="primary" @click="approvedTo()">审核通过</el-button>
      </span>
    </el-dialog>

    <!-- 售后处理提示框 -->
    <el-dialog title="售后处理" :visible.sync="reject" width="30%">
      <span>
        售后处理操作时，您需要选择处理类型，同时还需要填写具体原因和理由，也可以进行上传图片操作。
      </span>
      <!-- 下拉框 -->
      <div class="rejectContent">
        <div style="width: 80px">
          <span style="padding: right 15px">处理类型：</span>
        </div>
        <div style="flex: 1">
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 处理意见 -->
      <div class="rejectContent">
        <div style="width: 80px">
          <span style="padding: right 15px">处理意见：</span>
        </div>
        <div style="flex: 1">
          <el-input v-model="textarea" type="textarea" placeholder="请输入内容" maxlength="150" show-word-limit rows="5" />
        </div>
      </div>
      <!-- 上传图片 -->
      <Upimg @upimg="imgUrl" style="padding-left: 80px" :imgnumber="6"></Upimg>
      <span slot="footer" class="dialog-footer">
        <el-button @click="reject = false">取 消</el-button>
        <el-button type="primary" @click="rejectMethod()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("afterSalesOrder"); //vuex公共库
import Logistics from "../order/components/Logistics.vue";
import Upimg from "../order/components/Upimg.vue";
export default {
  components: {
    Logistics,
    Upimg,
  },
  data() {
    return {
      options: [
        {
          value: "1",
          label: "允许退款",
        },
        {
          value: "0",
          label: "不允许退款",
        },
      ],
      value: "",
      textarea: "",
      imgUrlData: [],
      reject: false,
      idData: "",

      id: "", //售后订单id
      // 售后单信息
      orderInformation: {
        reason: "", //退款原因
      },
      // 物流
      journal: [],

      state: true,
      // 审核通过
      approved: false,
      UrlImg: [],
      newUrlImg: [],
    };
  },
  created() {
    this.journal = this.logisticsIf;
  },
  mounted() {
    this.id = this.$route.query.id;
    if (this.$route.query.id) {
      this.setDetailsData({ id: this.$route.query.id });
    } else {
      this.setDetailsData({ id: this.afterSaleIdData });
    }
    if (this.$route.query.active == "审核") {
      this.state = false;
    } else {  
      this.state = true;
    }
  },
  computed: {
    ...mapState([
      "detailedData",
      "detailsList",
      "infoData",
      "logisticsIf",
      "afterSaleIdData",
    ]),
  },
  methods: {
    ...mapActions([
      "setDetailsData",
      "handle",
      "ofPage",
      "afterSalesOrderListData",
      "changeCount",
      "afterSalesOrderList",
    ]),
    imgUrl(item) {
      this.UrlImg = item;
    },

    emptyDate() {
      this.reject = true;
      this.UrlImg = [];
      this.newUrlImg = [];
      this.value = "";
      this.textarea = "";
    },
    getOrderDetails() {
      this.setDetailsData({ id: this.$route.query.id });
    },
    async rejectMethod() {
      if (this.value == "") {
        this.$message({
          message: "处理类型必选",
          type: "warning",
        });
        return;
      }
      this.newUrlImg = [];
      this.UrlImg.map((item) => {
        this.newUrlImg.push(item.url);
      });
      let data = await this.handle({
        id: this.detailedData.id,
        description: this.textarea,
        result: this.value,
        pic: this.newUrlImg,
      });
      this.reject = false;
      if (data.code == 200) {
        this.ofPage(true);
        this.afterSalesOrderList();
      }
    },
    ofMethod() {
      this.ofPage(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  min-width: 1000px;
  // padding: 20px;
  // padding-top: 10px;
  // border-radius: 4px;
  // box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  // background: #ffffff;
}
// .statusBar {
//   width: 100%;
//   height: 5px;
//   background: rgb(112, 182, 3);
// }
.statusInformation {
  display: flex;
  width: 100%;
  height: 210px;
  margin-bottom: 20px;
  // border: 1px solid #ccc;
  .statusLeft {
    width: 25%;
    height: 100%;
    // border-right: 1px solid #ccc;
    background-color: #fff;
    border-radius: 10px;
    .complete {
      color: #999;
      font-size: 18px;
      // text-align: center;
      // margin-top: 40px;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      span {
        color: rgb(244, 82, 82);
        font-weight: bolder;
        line-height: 16px;
        height: 16px;
      }
      span::before {
        width: 4px;
        height: 100%;
        background-color: #06b7ae;
        border-radius: 10px;
        display: inline-block;
        content: "";
        margin-right: 10px;
        margin-bottom: -2px;
      }
    }
    .information {
      color: rgb(127, 127, 127);
      font-size: 12px;
      padding: 20px;
      padding-top: 5px;
      // margin-left: 70px;
      // margin-top: 30px;
      p {
        line-height: 25px;
      }
    }
  }
  .statusMiddle {
    width: 8%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px 0 0 10px;
    margin-left: 20px;
    font-weight: normal;
    // border-right: 1px solid #ccc;
    .text {
      // text-align: right;
      // line-height: 60px;
      padding: 20px;
      font-size: 16px;
      // font-weight: bolder;
    }
  }
  .statusRight {
    flex: 1;
    height: 100%;
    overflow-y: scroll;
    background-color: #fff;
    border-radius: 0 10px 10px 0;
    .timeline {
      width: 100%;
      padding-top: 20px;
      // height: 900px;
      // border: 1px solid #000;
    }
  }
}
.shopText {
  margin-left: 30px;
  line-height: 25px;
  margin-bottom: 5px;
  h4 {
    font-size: 14px;
    font-weight: bolder;
    color: rgba(0, 0, 0, 0.8);
    span {
      font-size: 12px;
      background-color: #d9001b;
      color: #fff;
      margin-right: 5px;
    }
  }
  p {
    margin: 0;
  }
}
.shopMain {
  display: flex;
  align-content: center;
}
.refund {
  display: flex;
  height: 50px;
  color: #666666;
  font-weight: normal;
  font-size: 14px;
  // background: #eef1f6;
  align-items: center;
  justify-content: flex-end;
  .money {
    color: #e63e3f;
    font-weight: bold;
    margin-right: 50px;
    margin-left: 10px;
  }
}
.aftersale {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  .afterTitle {
    // line-height: 60px;
    padding-bottom: 20px;
    line-height: 18px;
    font-weight: bold;
    font-size: 18px;
  }
  .afterTitle::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .listData {
    display: flex;
    line-height: 100%;
    width: 100%;
    border-top: 0px;
    .listTitle {
      width: 120px;
      padding: 15px 10px;
      border: 1px solid #d4d7d9;
      border-top: 0px;
      background-color: #f7f8fa;
      color: #6d7687;
      font-size: 14px;
      text-align: center;
    }
    .listContent {
      flex: 1;
      padding: 15px 10px;
      border: 1px solid #d4d7d9;
      border-left: none;
      border-top: 0px;
      font-size: 14px;
      color: #2c384d;
    }
  }
  .borderTop {
    border-top: 1px solid #d4d7d9 !important;
  }
}
.bomBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.rejectContent {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
}
.tablebox {
  background-color: #fff;
  border-radius: 10px;
  padding: 0 20px 20px;
  box-sizing: border-box;
}
.tabhe {
  font-weight: bold;
  color: #828386;
  font-size: 15px;
  // background: #eef1f6;
  border-bottom: 1px solid #eee;
  height: 50px;
  line-height: 50px;
  margin: 10px 0px;
  text-align: center;
}
.tabhes {
  color: #828386;
  font-size: 13px;
  line-height: 50px;
  text-align: center;
}
.lsted {
  flex: 13;
  line-height: 130px;
}
.zengd {
  margin: -10px 0px 10px 8%;
  padding: 10px 15px;
  background-color: rgba(242, 242, 242, 1);
  color: #7f7f7f;
  line-height: 22px;
  font-size: 14px;
  border-radius: 10px;
}
</style>
